<template>
  <div class="auditor-container">
    <router-view></router-view>
  </div>
</template>
<script>
// Import Bootstrap CSS and JavaScript here
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
export default {
  mounted() { },
  props: [],
  async created() {
    localStorage.removeItem("publicUrlUser");
    localStorage.removeItem("token");
    localStorage.removeItem("auditorAuthToken");
    localStorage.removeItem("userPermissions");
    var publicexamAuthToken = localStorage.getItem("publicexamAuthToken");
    if (publicexamAuthToken) {
      this.$router.push("/publicexam/dashboard");
    } else {
      this.$router.push("/publicexam/login");
    }
    // this.$router.push({ name: 'your-route-name' });
  },
  components: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
/* .auditor-container{
background-color: #0da2a2;
} */
</style>
